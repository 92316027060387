<template>
   <CRow>
     <CCol col="12" xl="12">
       <CCard>
         <CCardHeader>
          Users Detail
        </CCardHeader>
        <CCardBody>
        <CForm @submit="submitEdit">
          <CRow>
            <CCol sm="6">
              <CInput
                label="Username"
                placeholder="Enter Username"
                v-model="data.member_username"
                disabled
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Member Name"
                placeholder="Enter Member Name"
                v-model="data.member_name"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Mobile Number"
                placeholder="Enter Mobile Number"
                v-model="data.member_mobile_no"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Email"
                placeholder="Enter Email"
                v-model="data.member_email"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="No KTP"
                placeholder="Enter No KTP"
                v-model="data.member_ktp_no"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Address"
                placeholder="Enter Address"
                v-model="data.member_address"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Package Rank"
                placeholder="Enter Package Rank"
                v-model="data.package_rank"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Bonus Rank"
                placeholder="Enter Bonus Rank"
                v-model="data.bonus_rank"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Cloud Cash Wallet"
                placeholder="Enter Cloud Cash Wallet"
                v-model="data.cloudcash_balance_wallet"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Bitcoin Wallet"
                placeholder="Enter Bitcoin Wallet"
                v-model="data.bitcoin_balance_wallet"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Total Team Bonus Earning"
                placeholder="Enter Total Team Bonus Earning"
                v-model="data.total_team_bonus_earning"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Capital Earning"
                placeholder="Enter Capital Earning"
                v-model="data.capital_earning"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Solitare Earning"
                placeholder="Enter Solitare Earning"
                v-model="data.solitare_earning"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Bohemian Earning"
                placeholder="Enter Bohemian Earning"
                v-model="data.bohemian_earning"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Executive Earning"
                placeholder="Enter Executive Earning"
                v-model="data.executive_earning"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Total Mining Matching Earning"
                placeholder="Enter Total Mining Matching Earning"
                v-model="data.total_mining_matching_earning"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Cloud Cash Total Withdraw Value"
                placeholder="Enter loud Cash Total Withdraw Value"
                v-model="data.cloudcash_total_withdraw_value"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="BTC Total Withdraw Value"
                placeholder="Enter BTC Total Withdraw Value"
                v-model="data.btc_total_withdraw_value"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CButton
                color="danger"
                square
                size="sm"
                @click="kembali()"
              >
                Back
              </CButton>
            </CCol>
            <CCol sm="6" align="right">
              <CButton
                color="success"
                square
                size="sm"
                type="submit"
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
      </CCard>
     </CCol>
   </CRow>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UsersDetail',
  data () {
    return {
      urlApi:this.$store.state.urlApi,
      usersOpened: null,
      userId:null,
      data:{
        member_username: null,
        member_name: null,
        member_mobile_no: null,
        member_email: null,
        member_ktp_no: null,
        member_address: null,
        package_rank: null,
        bonus_rank: null,
        register_date: null,
        cloudcash_balance_wallet: null,
        bitcoin_balance_wallet: null,
        total_team_bonus_earning: null,
        capital_earning: null,
        solitare_earning: null,
        bohemian_earning: null,
        executive_earning: null,
        total_mining_matching_earning: null,
        cloudcash_total_withdraw_value: null,
        btc_total_withdraw_value: null
      }
    }
  },
  mounted(){
    this.userId = this.$route.params.id
    axios.get(`${this.urlApi}/api/user-details/${this.userId}`).then(response => {
      this.data = response.data
    })
  },
  methods: {
    kembali(){
      // console.log(this.$router)
      this.$router.go(-1)
    },
    submitEdit(evt) {
      evt.preventDefault()

      axios.put(`${this.urlApi}/api/user-details/${this.userId}`,this.data).then(response => {
        this.$router.go(-1)
      })
    }
  },
  
}
</script>
